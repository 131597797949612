/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@components/Layouts"
import slugify from "slugify"
import TwoColumnSection, {
  Block as ColumnBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import CardResults from "@components/CardResults"
import SEO from "@components/seo"
import { getSlug } from "@helpers/utils/getSlug"

const TAG_OPTIONS = [
  { label: "digital" },
  { label: "printable" },
  { label: "written" },
]

export default props => {
  const data = useStaticQuery(
    graphql`
      query {
        resources: allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/posts/resource/*.md" } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                slug
                image
                pdf
                link
                cardPreview
                tags
              }
            }
          }
        }

        og: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/resources/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  const { ogTitle, header, ogimage, ogdescription } = data.og.frontmatter

  return (
    <Layout>
      <SEO
        title={ogTitle || header?.heading}
        image={ogimage}
        description={ogdescription}
        pathname={props.location.pathname}
      />
      <TwoColumnSection
        col1={
          <ColumnBlock
            styles={{
              bg: "purple",
              color: "pink",
            }}
          >
            <CTAHeadingBlock heading={header.heading} />
          </ColumnBlock>
        }
        col2={
          <ColumnBlock
            styles={{
              bg: "darkPurple",
              color: "white",
            }}
          >
            <div>
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
              />
            </div>
          </ColumnBlock>
        }
      />

      <div sx={{ bg: "pink" }}>
        <CardResults
          items={data.resources.edges.map(resource => ({
            ...resource,
            slug: getSlug(resource),
            preview: resource.node.frontmatter.cardPreview,
          }))}
          heading="Resources"
          TAG_OPTIONS={TAG_OPTIONS}
          type="Resources"
          {...props}
        />
      </div>
    </Layout>
  )
}
